import React from 'react';
import { DefaultView } from '@plone/volto/components';

const VulnerabilityView = (props) => {
  return (
    <>
      <DefaultView {...props} />;
    </>
  );
};

export default VulnerabilityView;
