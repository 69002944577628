/**
 * Add your config changes here.
 * @module config
 * @example
 * export default function applyConfig(config) {
 *   config.settings = {
 *     ...config.settings,
 *     port: 4300,
 *     listBlockTypes: {
 *       ...config.settings.listBlockTypes,
 *       'my-list-item',
 *    }
 * }
 */
import {
  config as faConfig,
  dom as faDom,
  library,
} from '@fortawesome/fontawesome-svg-core';
import * as IconsRegular from '@fortawesome/free-regular-svg-icons';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import { CustomCSS, LangHeader } from '@package/components';
import applyAccordionConfig from '@package/config/Accordion/config';
import { SiteBlocks } from '@package/config/Blocks';
import applyRichTextConfig from '@package/config/RichTextEditor/config';
import { SiteViews } from '@package/config/Views';
import { SiteWidgets } from '@package/config/Widgets';
import '@plone/volto/config';
import { useGoogleAnalytics } from 'volto-google-analytics';

faConfig.autoAddCss = false;
const iconList = Object.keys(Icons.fas).map((icon) => Icons[icon]);
const iconListRegular = Object.keys(IconsRegular.far).map(
  (icon) => IconsRegular[icon],
);

library.add(...iconList, ...iconListRegular);

function GoogleAnalyticsLoader() {
  useGoogleAnalytics();
  return null;
}

export default function applyConfig(config) {
  config = applyRichTextConfig(config);
  config = applyAccordionConfig(config);

  config.settings = {
    ...config.settings,
    isMultilingual: false,
    supportedLanguages: ['en'],
    defaultLanguage: 'en',
    showTags: false,
    appExtras: [
      ...config.settings.appExtras,
      {
        match: '',
        component: () => <style type="text/css">{faDom.css()}</style>, //load fontawesom dom css
      },
      { match: '', component: CustomCSS },
      { match: '', component: LangHeader },
    ],
    hasWorkingCopySupport: true,
  };

  config.blocks.blocksConfig.__grid = {
    ...config.blocks.blocksConfig.__grid,
    gridAllowedBlocks: ['teaser', 'image', 'slate', 'html'],
  };

  config.settings['volto-gdpr-privacy'] = {
    settings: {
      ...config.settings['volto-gdpr-privacy'].settings,
      GANALYTICS: {
        // Defined above because `component` isn't upercase and isn't compatible with hooks
        component: GoogleAnalyticsLoader,
      },
      //   component: () => {
      //     return (
      //       <>
      //         <h2>Hello world</h2>
      //         <GoogleAnalyticsLoader />
      //       </>
      //     );
      //   },
      // },
    },
    defaultPanelConfig: {
      last_updated: '2022-12-10T00:07:00+00:00',
      focusTrapEnabled: false,
      text: {
        en: {
          title: 'This site uses cookies',
          description: `We use some essential cookies to make parts of this website work. \n
        We would like to set some additional cookies to understand how you use this website for us to improve it.
        We also use cookies set by external services such as YouTube and Maps. These might set cookies and collect personal data that can be used for profiling purposes across websites. You can disable individual services below.
    `,
        },
      },
      profiling: {
        text: {
          en: {
            title: 'Measurement cookies',
          },
        },
        choices: [
          // Google Analytics
          ...(config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical
            ?.choices ?? []),
          // YouTube & Maps
          ...config.settings[
            'volto-gdpr-privacy'
          ].defaultPanelConfig.profiling.choices.filter((choice) =>
            ['YOUTUBE', 'GOOGLEMAPS'].includes(choice.config_key),
          ),
        ],
      },
    },
  };

  SiteBlocks(config); //blocks configuration for Plone.org
  SiteWidgets(config); //widgets configuration for Plone.org
  SiteViews(config); //views configuration for Plone.org

  config.settings['volto-blocks-widget'] = {
    allowedBlocks: Object.keys(config.blocks.blocksConfig),
    showRestricted: false,
  };

  return config;
}
