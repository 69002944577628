/**
 * Footer component.
 * @module components/theme/Footer/Footer
 */

import { UniversalLink } from '@plone/volto/components';
import React from 'react';
import { injectIntl } from 'react-intl';
import { Container, Grid, List } from 'semantic-ui-react';

import { useDispatch } from 'react-redux';
import { displayBanner } from 'volto-gdpr-privacy';

/**
 * Component to display the footer.
 * @function Footer
 * @param {Object} intl Intl object
 * @returns {string} Markup of the component
 */
const Footer = ({ intl }) => {
  const dispatch = useDispatch();

  return (
    <footer id="footer">
      <Container>
        <div id="footer-main">
          <Grid textAlign="left">
            <Grid.Row columns={2} relaxed="very">
              <Grid.Column>
                <h3>PretaGov UK</h3>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <p>
                        Registered Office:
                        <br />
                        Suite 2A, Blackthorn House,
                        <br />
                        St Pauls Square,
                        <br />
                        Birmingham,
                        <br />
                        B3 1RL
                      </p>
                      <p>
                        P:{' '}
                        <a href="tel:+44_208_819_3887">+44 (0) 208 819 3887</a>
                      </p>
                      <p>
                        E:{' '}
                        <a href="mailto:contact@pretagov.co.uk">
                          contact@pretagov.co.uk
                        </a>
                      </p>
                    </Grid.Column>
                    <Grid.Column>
                      <ul className="suppliers">
                        <li>
                          <img src="/CCS_WHITE_Supplier_AW_sized.svg" alt="" />
                        </li>
                      </ul>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
              <Grid.Column>
                <h3>PretaGov Australia</h3>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <p>
                        Registered Office:
                        <br />
                        Suite 97,Level 3,
                        <br />
                        515 Kent Street,
                        <br />
                        Sydney NSW 2000
                      </p>
                      <p>
                        P: <a href="tel:+61_9955_2830">+61 (2) 9955 2830</a>
                      </p>
                      <p>
                        E:{' '}
                        <a href="mailto:contact@pretagov.com.au">
                          contact@pretagov.com.au
                        </a>
                      </p>
                    </Grid.Column>
                    <Grid.Column>
                      <ul className="suppliers">
                        <li>
                          <a href="https://buy.nsw.gov.au/supplier/profile/637">
                            Supplier to <br />
                            <strong style={{ fontSize: '2.0em' }}>
                              buy.nsw
                            </strong>
                          </a>
                        </li>
                        <li>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <img
                              src="/australian-government-stacked-white.png"
                              alt=""
                            />
                            <div>
                              <strong style={{ fontSize: '1.6em' }}>
                                Buy ICT
                              </strong>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column>
                <List horizontal>
                  <List.Item>
                    <List.Content>
                      <UniversalLink href="/blog">Blog</UniversalLink>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <UniversalLink href="/privacy-statement">
                        Privacy
                      </UniversalLink>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <UniversalLink href="/anti-slavery-statement">
                        Anti-slavery
                      </UniversalLink>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <UniversalLink href="/accessibility-statement">
                        Accessibility
                      </UniversalLink>
                    </List.Content>
                  </List.Item>
                  <List.Item>
                    <List.Content>
                      <button
                        onClick={() => dispatch(displayBanner(true, true))}
                      >
                        Cookies
                      </button>
                    </List.Content>
                  </List.Item>
                </List>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </Container>
    </footer>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Footer.propTypes = {
  /**
   * i18n object
   */
};

export default injectIntl(Footer);
