import { Helmet } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';
import config from '@plone/volto/registry';

export function LangHeader() {
  const { pathname } = useSelector((state) => state.router.location);

  return (
    <>
      <Helmet
        link={[
          {
            rel: 'alternate',
            hrefLang: 'en-GB',
            href: `https://www.pretagov.co.uk${pathname}`,
          },
          {
            rel: 'alternate',
            hrefLang: 'en-AU',
            href: `https://www.pretagov.com.au${pathname}`,
          },
          {
            rel: 'alternate',
            hrefLang: 'x-default',
            href: `https://www.pretagov.com${pathname}`,
          },
          {
            rel: 'canonical',
            href: `${config.settings.publicURL}${pathname}`,
          },
        ]}
      />
    </>
  );
}
