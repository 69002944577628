import CookieGroupSettings from 'volto-gdpr-privacy/components/CookieBanner/CookieGroupSettings';

const CookieSettings = ({ preferences, setPreferences, panelConfig }) => {
  return (
    <div className="gdpr-privacy-settings">
      {/******** TECHNICAL ********/}
      {panelConfig.technical?.choices?.length > 0 && (
        <div className="settings-column technical">
          <CookieGroupSettings
            id="technical"
            groupConfig={panelConfig.technical}
            disabled={true}
            preferences={preferences}
            setPreferences={setPreferences}
          />
        </div>
      )}

      {/******** PROFILING ********/}
      {panelConfig.profiling?.choices?.length > 0 && (
        <div className="settings-column profiling">
          <CookieGroupSettings
            id="profiling"
            groupConfig={panelConfig.profiling}
            disabled={false}
            preferences={preferences}
            setPreferences={setPreferences}
            autofocus={true}
          />
        </div>
      )}
    </div>
  );
};

export default CookieSettings;
